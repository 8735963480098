import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, ConfirmModal, ErrorPage, FormModal, generateId, HasProductRole, Icon, Icons, ModalLauncher, NoPermission, Products, SchedulingRoles, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextField, useAlertState, useAuthState, View } from "@barscience/global-components";
import { useState } from "react";

/* Get Labor Categories Query */
const GET_ALL_LABOR_CATEGORIES = gql`
query getAllLaborCategories {
  laborCategories {
    id
    name
    jobs {
      id
      name
      isArchived
    }
  }
}
`;

type GetAllLaborCategoriesResponse = {
  laborCategories: LaborCategory[];
}

type LaborCategory = {
  id: string;
  name: string;
  jobs: ScheduleJob[];
}

type ScheduleJob = {
  id: string;
  name: string;
  isArchived: boolean;
}

/* Create Labor Category Mutation */
const CREATE_LABOR_CATEGORY = gql`
mutation createLaborCategory($name: String!) {
  createLaborCategory(name: $name) {
    id
    name
    jobs {
      id
      name
      isArchived
    }
  }
}
`;

type CreateLaborCategoryResponse = {
  createLaborCategory: LaborCategory | null;
}

type CreateLaborCategoryInput = {
  categoryName: string;
}

/* Edit Labor Category Mutation */
const EDIT_LABOR_CATEGORY = gql`
mutation editLaborCategory($id: ID!, $name: String!) {
  editLaborCategory(id: $id, name: $name) {
    id
    name
    jobs {
      id
      name
      isArchived
    }
  }
}
`;

type EditLaborCategoryInput = {
  id: string;
  categoryName: string;
}

/* Delete Labor Category Mutation */
const DELETE_LABOR_CATEGORY = gql`
mutation deleteLaborCategory($id: ID!) {
  success: deleteLaborCategory(id: $id)
}
`;

type DeleteLaborCategoryResponse = {
  success: boolean;
}

export default function LaborCategories() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { data: categoryData, loading: categoriesAreLoading, error: categoryError } = useQuery<GetAllLaborCategoriesResponse>(GET_ALL_LABOR_CATEGORIES);
  const [createLaborCategory] = useMutation<CreateLaborCategoryResponse>(CREATE_LABOR_CATEGORY, {
    refetchQueries: [GET_ALL_LABOR_CATEGORIES],
  });

  /* Create Labor Category */
  const handleCreateLaborCategory = async (values: CreateLaborCategoryInput) => {
    const { errors } = await createLaborCategory({
      variables: {
        name: values.categoryName,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating labor category' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Labor category created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createLaborCategoryModal = (
    <FormModal<CreateLaborCategoryInput> title='Create Labor Category' onSubmit={handleCreateLaborCategory} submitLabel='Create' initialValues={{ categoryName: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField name='categoryName' label='Category Name' required />
      </View>
    </FormModal>
  );

  if (state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin && state.user?.roles[Products.Scheduling] !== SchedulingRoles.Manager) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (categoryError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/settings' />
          <Breadcrumb label='Labor Categories' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', maxWidth: '800px', marginBottom: '16px', '@media (max-width: 470px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
          <StyledHeading tag='h3'>Labor Categories</StyledHeading>

          <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
            <ModalLauncher modal={createLaborCategoryModal}>
              {({ openModal }) => (
                <Button label='Create Category' role='button' variant='primary' action={openModal} />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {categoriesAreLoading ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <View style={{ boxSizing: 'border-box', maxWidth: '800px', gap: '16px' }}>
            {categoryData?.laborCategories.length === 0 ?
              <Card style={{ boxSizing: 'border-box', width: '100%' }}>
                <StyledParagraph>No labor categories found.</StyledParagraph>
              </Card>
              :
              <>
                {categoryData?.laborCategories.map((category) => (
                  <LaborCategoryCard key={category.id} category={category} />
                ))}
              </>
            }
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}

type LaborCategoryCardProps = {
  category: LaborCategory;
}

function LaborCategoryCard(props: LaborCategoryCardProps) {
  const { addAlert } = useAlertState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editLaborCategory] = useMutation(EDIT_LABOR_CATEGORY);
  const [deleteLaborCategory] = useMutation<DeleteLaborCategoryResponse>(DELETE_LABOR_CATEGORY, {
    update(cache, { data }) {
      if (!data?.success) {
        return;
      }

      cache.evict({
        id: cache.identify(props.category)
      });
    },
  });

  const activeJobs = props.category.jobs.filter((job) => !job.isArchived);
  const archivedJobs = props.category.jobs.filter((job) => job.isArchived);

  /* Edit Labor Category */
  const handleEditLaborCategory = async (values: EditLaborCategoryInput) => {
    const { errors } = await editLaborCategory({
      variables: {
        id: values.id,
        name: values.categoryName,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error updating labor category' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editLaborCategoryModal = (
    <FormModal<EditLaborCategoryInput> title='Edut Labor Category' onSubmit={handleEditLaborCategory} initialValues={{ id: '', categoryName: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField name='categoryName' label='Category Name' required />
      </View>
    </FormModal>
  );

  /* Delete Labor Category */
  const handleDeleteLaborCategory = async () => {
    const { errors } = await deleteLaborCategory({
      variables: {
        id: props.category.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting labor category' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Labor category deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteLaborCategoryModal = (
    <ConfirmModal title='Delete Labor Category?' onConfirm={handleDeleteLaborCategory} confirmLabel='Delete' destructive>
      <StyledParagraph>This labor category will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  return (
    <Card style={{ boxSizing: 'border-box', width: '100%' }}>
      <View style={{ gap: '16px' }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px', width: 'fit-content' }} onClick={() => { setIsOpen(!isOpen); }}>
            {isOpen ? <Icon icon={Icons.ChevronDown} size='small' key='icon-open' /> : <Icon icon={Icons.ChevronRight} size='small' key='icon-closed' />}
            <StyledHeading tag='h6'>{props.category.name}</StyledHeading>
          </View>

          <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
            <ModalLauncher modal={editLaborCategoryModal}>
              {({ openModal: openEditModal }) => (
                <ModalLauncher modal={deleteLaborCategoryModal}>
                  {({ openModal: openDeleteModal }) => (
                    <ActionMenu>
                      <ActionItem label='Edit' onClick={openEditModal} data={{ id: props.category.id, categoryName: props.category.name }} />
                      {props.category.jobs.length === 0 && <ActionItem label='Delete' onClick={openDeleteModal} />}
                    </ActionMenu>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>

        {isOpen &&
          <View style={{ marginLeft: '32px', gap: '24px' }}>
            {activeJobs.length > 0 &&
              <View style={{ gap: '8px' }}>
                <StyledParagraph bold>Active Jobs</StyledParagraph>
                {activeJobs.map((job) => (
                  <StyledParagraph>{job.name}</StyledParagraph>
                ))}
              </View>
            }
            {archivedJobs.length > 0 &&
              <View style={{ gap: '8px' }}>
                <StyledParagraph bold>Archived Jobs</StyledParagraph>
                {archivedJobs.map((job) => (
                  <StyledParagraph>{job.name}</StyledParagraph>
                ))}
              </View>
            }

            {(archivedJobs.length === 0 && activeJobs.length === 0) &&
              <StyledParagraph>No jobs are assigned to this labor category.</StyledParagraph>
            }
          </View>
        }
      </View>
    </Card>
  );
}