import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Colors, ConfirmModal, ErrorPage, FormModal, FormModalValueProvider, generateId, HasProductRole, ModalLauncher, NoPermission, OptionBar, OptionItem, Products, SchedulingRoles, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, useAlertState, useAuthState, View } from "@barscience/global-components";
import { useState } from "react";

/* Get Stations Query */
const GET_STATIONS = gql`
query getAllStations($isArchived: Boolean!) {
  scheduleJobStations(isArchived: $isArchived) {
    id
    name
    isArchived
    assignedToJobs {
      id
      name
    }
  }
}
`;

type GetStationsResponse = {
  scheduleJobStations: Station[] | null;
}

type Station = {
  id: string;
  name: string;
  isArchived: boolean;
  assignedToJobs: {
    id: string;
    name: string;
  }[];
}

/* Create Station Mutation */
const CREATE_STATION = gql`
mutation createScheduleJobStation($name: String!) {
  createScheduleJobStation(name: $name) {
    id
    name
    isArchived
    assignedToJobs {
      id
      name
    }
  }
}
`;

type CreateStationResponse = {
  createScheduleJobStation: Station | null;
}

type CreateStationInput = {
  stationName: string;
}

/* Edit Station Mutation */
const EDIT_STATION = gql`
mutation editScheduleJobStation($id: ID!, $name: String!) {
  editScheduleJobStation(id: $id, name: $name) {
    id
    name
  }
}
`;

type EditStationInput = {
  id: string;
  stationName: string;
}

/* Archive Station Mutation */
const ARCHIVE_STATION = gql`
mutation archiveScheduleJobStation($id: ID!) {
  archiveScheduleJobStation(id: $id) {
    id
    isArchived
  }
}
`;

/* Unarchive Station Mutation */
const UNARCHIVE_STATION = gql`
mutation unarchiveScheduleJobStation($id: ID!) {
  unarchiveScheduleJobStation(id: $id) {
    id
    isArchived
  }
}
`;

/* Delete Station Mutation */
const DELETE_STATION = gql`
mutation deleteScheduleJobStation($id: ID!) {
  success: deleteScheduleJobStation(id: $id)
}
`;

type DeleteStationResponse = {
  success: boolean | null;
}

type DeleteStationInput = {
  id: string;
  stationName: string;
  confirmStationName: string;
}

export default function Stations() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [showArchived, setShowArchived] = useState('FALSE');
  const { data: stationData, loading: stationsAreLoading, error: stationsError } = useQuery<GetStationsResponse>(GET_STATIONS, {
    variables: {
      isArchived: showArchived === 'TRUE',
    },
  });
  const [createStation] = useMutation<CreateStationResponse>(CREATE_STATION, {
    update(cache, { data }) {
      if (!data?.createScheduleJobStation) {
        return;
      }

      cache.modify({
        fields: {
          scheduleJobStations(existingStations = [], { readField }) {
            return [data.createScheduleJobStation, ...existingStations].sort((a, b) => {
              const aName = readField('name', a)?.toString().toLocaleLowerCase() || '';
              const bName = readField('name', b)?.toString().toLocaleLowerCase() || '';

              return aName.localeCompare(bName);
            });
          }
        }
      });
    }
  });
  const [editStation] = useMutation(EDIT_STATION);
  const [archiveStation] = useMutation(ARCHIVE_STATION, {
    refetchQueries: [{ query: GET_STATIONS, variables: { isArchived: false } }, { query: GET_STATIONS, variables: { isArchived: true } }],
  });
  const [unarchiveStation] = useMutation(UNARCHIVE_STATION, {
    refetchQueries: [{ query: GET_STATIONS, variables: { isArchived: false } }, { query: GET_STATIONS, variables: { isArchived: true } }],
  });
  const [deleteStation] = useMutation<DeleteStationResponse>(DELETE_STATION, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables?.id) {
        return;
      }

      const station = stationData?.scheduleJobStations?.find((station) => station.id === variables.id);
      if (!station) {
        return;
      }

      cache.evict({
        id: cache.identify(station),
      });
    },
  });

  /* Create Station */
  const handleCreateStation = async (values: CreateStationInput) => {
    if (!values.stationName) {
      return;
    }

    const { errors } = await createStation({
      variables: {
        name: values.stationName,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating station' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Station created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createStationModal = (
    <FormModal<CreateStationInput> title='Create Station' onSubmit={handleCreateStation} submitLabel='Create' initialValues={{ stationName: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Station Name' name='stationName' required />
      </View>
    </FormModal>
  );

  /* Edit Station */
  const handleEditStation = async (values: EditStationInput) => {
    const { errors } = await editStation({
      variables: {
        id: values.id,
        name: values.stationName,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error editing station' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editStationModal = (
    <FormModal<EditStationInput> title='Edit Station' onSubmit={handleEditStation} initialValues={{ id: '', stationName: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Station Name' name='stationName' required />
      </View>
    </FormModal>
  );

  /* Archive Station */
  const handleArchiveStation = async (id: string) => {
    const { errors } = await archiveStation({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error archiving station' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Station archived' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const archiveStationModal = (
    <ConfirmModal title='Archive Station?' onConfirm={handleArchiveStation} confirmLabel='Archive'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This station will be archived. You can unarchive it later.</StyledParagraph>
        <StyledParagraph>After archiving, this station will not appear in the scheduler or on employee profiles.</StyledParagraph>
        <StyledParagraph>Existing shifts assigned to this station will be unaffected.</StyledParagraph>
      </View>
    </ConfirmModal>
  )

  /* Restore Station */
  const handleRestoreStation = async (id: string) => {
    const { errors } = await unarchiveStation({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error restoring station' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Station restored' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const restoreStationModal = (
    <ConfirmModal title='Restore Station?' onConfirm={handleRestoreStation} confirmLabel='Restore'>
      <StyledParagraph>After restoring, this station will appear in the scheduler and can be assigned to new and existing shifts.</StyledParagraph>
    </ConfirmModal>
  );

  /* Delete Station */
  const handleDeleteStation = async (values: DeleteStationInput) => {
    if (values.stationName !== values.confirmStationName) {
      return;
    }

    const { errors } = await deleteStation({
      variables: {
        id: values.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting station' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Station deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteStationModal = (
    <FormModal<DeleteStationInput> title='Delete Station?' onSubmit={handleDeleteStation} submitLabel='Delete' destructive initialValues={{ id: '', stationName: '', confirmStationName: '' }}>
      <FormModalValueProvider>
        {({ getValue }) => (
          <View style={{ gap: '16px' }}>
            <StyledParagraph bold style={{ color: Colors.error500 }}>This station will be permanently deleted. Any shifts assigned to this station will be reassigned to no station.</StyledParagraph>
            <TextField label='Type the name of this station to proceed' description={`Enter "${getValue && getValue('stationName')}" below`} name='confirmStationName' required validate={(_, value: string) => {
              if (value !== (getValue ? getValue('stationName') : 'ERROR')) {
                return 'Name does not match';
              }

              return null;
            }} />
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  if (state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin && state.user?.roles[Products.Scheduling] !== SchedulingRoles.Manager) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (stationsError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/settings' />
          <Breadcrumb label='Stations' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', maxWidth: '1000px', marginBottom: '16px', '@media (max-width: 470px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
          <StyledHeading tag='h3'>Stations</StyledHeading>

          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
            <OptionBar selectedValue={showArchived} onChange={setShowArchived}>
              <OptionItem label='Active' value='FALSE' />
              <OptionItem label='Archived' value='TRUE' />
            </OptionBar>

            <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
              <ModalLauncher modal={createStationModal}>
                {({ openModal }) => (
                  <Button label='Create Station' role='button' variant='primary' action={openModal} />
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {stationsAreLoading ?
          <View style={{ boxSizing: 'border-box', maxWidth: '1000px', gap: '16px' }}>
            <AtomSpinner size='medium' />
          </View>
          :
          <View style={{ boxSizing: 'border-box', maxWidth: '1000px', gap: '16px' }}>
            <Card style={{ boxSizing: 'border-box', width: '100%' }}>
              {(stationData?.scheduleJobStations || []).length === 0 ?
                <StyledParagraph>No stations found.</StyledParagraph>
                :
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>Name</TableHeaderCell>
                      <TableHeaderCell>Assigned to jobs</TableHeaderCell>
                      <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
                        <TableHeaderCell style={{ margin: '0px', padding: '0px', width: '16px' }}></TableHeaderCell>
                      </HasProductRole>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {stationData?.scheduleJobStations?.map((station) => (
                      <TableRow key={station.id}>
                        <TableCell>{station.name}</TableCell>
                        <TableCell>
                          {station.assignedToJobs.length === 0 ? 'None' : station.assignedToJobs.map((job) => job.name).join(', ')}
                        </TableCell>
                        <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
                          <TableCell style={{ margin: '0px', padding: '0px', width: '16px' }}>
                            <ModalLauncher modal={editStationModal}>
                              {({ openModal: openEditModal }) => (
                                <ModalLauncher modal={archiveStationModal}>
                                  {({ openModal: openArchiveModal }) => (
                                    <ModalLauncher modal={restoreStationModal}>
                                      {({ openModal: openRestoreModal }) => (
                                        <ModalLauncher modal={deleteStationModal}>
                                          {({ openModal: openDeleteModal }) => (
                                            <ActionMenu>
                                              {!station.isArchived && <ActionItem label='Edit' onClick={() => { openEditModal({ id: station.id, stationName: station.name }); }} />}
                                              {!station.isArchived && <ActionItem label='Archive' onClick={() => { openArchiveModal(station.id); }} />}
                                              {station.isArchived && <ActionItem label='Restore' onClick={() => { openRestoreModal(station.id); }} />}
                                              {station.isArchived && <ActionItem label='Delete' onClick={() => { openDeleteModal({ id: station.id, stationName: station.name, confirmStationName: '' }); }} />}
                                            </ActionMenu>
                                          )}
                                        </ModalLauncher>
                                      )}
                                    </ModalLauncher>
                                  )}
                                </ModalLauncher>
                              )}
                            </ModalLauncher>
                          </TableCell>
                        </HasProductRole>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
            </Card>
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}