import { ExternalRedirect, HasProductRole, Header, Icons, Products, SchedulingRoles, Sidebar, SidebarItem, useAlertState, useAuthState, View } from '@barscience/global-components';
import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Settings from './pages/settings/Settings';
import LaborCategories from './pages/settings/LaborCategories';
import Jobs from './pages/settings/Jobs';
import Stations from './pages/settings/Stations';
import Schedules from './pages/settings/Schedules';
import AdvancedSettings from './pages/settings/AdvancedSettings';

function App() {
  const { state } = useAuthState();
  const { alerts } = useAlertState();
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  if (!state.user) {
    let authUrl = 'https://auth.barscience.us/login';
    if (window.location.href.includes('-dev')) {
      authUrl = 'https://auth-dev.barscience.us/login';
    } else if (!window.location.href.includes('barscience.us')) {
      authUrl = `http://${window.location.hostname}:6002/login`;
    }
    return (
      <ExternalRedirect to={authUrl + '?continue=' + window.location.href} />
    );
  }

  if (!state.user.roles[Products.Scheduling]) {
    return (
      <ExternalRedirect to='https://start.barscience.us' />
    );
  }

  return (
    <>
      {alerts.length > 0 &&
        <View style={{ bottom: '24px', gap: '16px', left: '24px', position: 'fixed', zIndex: 1 }}>
          {alerts.map((a) => {
            return (
              <React.Fragment key={a.id}>
                {a.alert}
              </React.Fragment>
            );
          })}
        </View>
      }
      <View style={{ height: '100vh' }}>
        <Header product='scheduling' onSidebarClick={toggleSidebar} />

        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          <Sidebar isOpen={sidebarIsOpen}>
            <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
              <SidebarItem label='Settings' to='/settings' icon={Icons.Admin} includeSubpaths />
            </HasProductRole>
          </Sidebar>

          <Routes>
            <Route path='/settings' element={ <Settings />} />
            <Route path='/settings/labor-categories' element={<LaborCategories />} />
            <Route path='/settings/jobs' element={<Jobs />} />
            <Route path='/settings/stations' element={<Stations />} />
            <Route path='/settings/schedules' element={<Schedules />} />
            <Route path='/settings/advanced' element={<AdvancedSettings />} />

            <Route path='/*' element={<Navigate to='/' />} />
          </Routes>
        </View>
      </View>
    </>
  );
}

export default App;
