import { gql, Reference, StoreObject, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Checkbox, Choice, CircularSpinner, Colors, ConfirmModal, ErrorPage, Form, FormModal, generateId, HasProductRole, Icon, Icons, InfoModal, ModalLauncher, NoPermission, OptionBar, OptionItem, Products, SchedulingRoles, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableRow, TextField, useAlertState, useAuthState, useForm, View } from "@barscience/global-components";
import { useEffect, useState } from "react";

/* Get Jobs Query */
const GET_ALL_JOBS = gql`
query getAllScheduleJobs($isArchived: Boolean!) {
  scheduleJobs(isArchived: $isArchived) {
    id
    name
    defaultRate
    laborCategory {
      id
      name
    }
    isArchived
    stations {
      station {
        id
        name
      }
      automaticallyAddToEmployees
    }
  }
}
`;

type GetAllJobsResponse = {
  scheduleJobs?: ScheduleJob[];
};

type ScheduleJob = {
  id: string;
  name: string;
  defaultRate: number;
  laborCategory: {
    id: string;
    name: string;
  };
  isArchived: boolean;
  stations: StationAssignment[];
}

type StationAssignment = {
  station: {
    id: string;
    name: string;
  };
  automaticallyAddToEmployees: boolean;
}

/* Get Labor Categories Query */
const GET_ALL_LABOR_CATEGORIES = gql`
query getAllLaborCategoriesForJobCreation {
  laborCategories {
    id
    name
  }
}
`;

type GetAllLaborCategoriesResponse = {
  laborCategories: LaborCategory[];
}

type LaborCategory = {
  id: string;
  name: string;
}

/* Create Job Mutation */
const CREATE_JOB = gql`
mutation createScheduleJob($input: CreateScheduleJobInput!) {
  createScheduleJob(input: $input) {
    id
    name
    defaultRate
    laborCategory {
      id
      name
    }
    isArchived
  }
}
`;

type CreateJobInput = {
  jobName: string;
  defaultRate: string;
  laborCategoryId: string;
}

/* Edit Job Mutation */
const EDIT_JOB = gql`
mutation editScheduleJob($id: ID!, $input: EditScheduleJobInput!) {
  editScheduleJob(id: $id, input: $input) {
    id
    name
    defaultRate
    laborCategory {
      id
      name
    }
    isArchived
  }
}
`;

type EditJobInput = {
  jobId: string;
  jobName: string;
  defaultRate: string;
  laborCategoryId: string;
}

/* Archive Job Mutation */
const ARCHIVE_JOB = gql`
mutation archiveScheduleJob($id: ID!) {
  archiveScheduleJob(id: $id) {
    id
    isArchived
  }
}
`;

/* Unarchive Job Mutation */
const UNARCHIVE_JOB = gql`
mutation unarchiveScheduleJob($id: ID!) {
  unarchiveScheduleJob(id: $id) {
    id
    isArchived
  }
}
`;

/* Delete Job Mutation */
const DELETE_JOB = gql`
mutation deleteScheduleJob($id: ID!) {
  success: deleteScheduleJob(id: $id)
}
`;

type DeleteJobResponse = {
  success: boolean;
}

type DeleteJobInput = {
  jobName: string;
}

/* Get Stations Query */
const GET_STATIONS = gql`
query getStationsToAssignToJobs($isArchived: Boolean!) {
  scheduleJobStations(isArchived: $isArchived) {
    id
    name
  }
}
`;

type GetStationsResponse = {
  scheduleJobStations?: Station[];
};

type Station = {
  id: string;
  name: string;
}

/* Assign Station Mutation */
const ASSIGN_STATION_TO_JOB = gql`
mutation assignStationToScheduleJob($stationId: ID!, $jobId: ID!, $automaticallyAddToEmployees: Boolean!) {
  assignStationToScheduleJob(stationId: $stationId, jobId: $jobId, automaticallyAddToEmployees: $automaticallyAddToEmployees) {
    station {
      id
      name
      assignedToJobs {
        id
        name
      }
    }
    automaticallyAddToEmployees
  }
}
`;

type AssignStationToJobInput = {
  stationId: string;
  automaticallyAddToEmployees: boolean;
}

type AssignStationToJobResponse = {
  assignStationToScheduleJob: StationJobAssignment | null;
}

type StationJobAssignment = {
  station: {
    id: string;
    name: string;
    assignedToJobs: {
      id: string;
      name: string;
    }[];
  }
  automaticallyAddToEmployees: boolean;
}

/* Edit Station Assignment Mutation */
const EDIT_STATION_ASSIGNMENT = gql`
mutation editStationScheduleJobAssignment($stationId: ID!, $jobId: ID!, $automaticallyAddToEmployees: Boolean!) {
  editStationScheduleJobAssignment(stationId: $stationId, jobId: $jobId, automaticallyAddToEmployees: $automaticallyAddToEmployees) {
    station {
      id
      name
      assignedToJobs {
        id
        name
      }
    }
    automaticallyAddToEmployees
  }
}
`;

type EditStationAssignmentResponse = {
  editStationScheduleJobAssignment: StationJobAssignment;
}

/* Delete Station Assignment Mutation */
const DELETE_STATION_ASSIGNMENT = gql`
mutation deleteStationScheduleJobAssignment($stationId: ID!, $jobId: ID!) {
  success: deleteStationScheduleJobAssignment(stationId: $stationId, jobId: $jobId)
}
`;

type DeleteStationAssignmentResponse = {
  success: boolean;
}

export default function Jobs() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [showArchived, setShowArchived] = useState('FALSE');
  const { data: jobData, loading: jobsAreLoading, error: jobsError } = useQuery<GetAllJobsResponse>(GET_ALL_JOBS, {
    variables: {
      isArchived: showArchived === 'TRUE',
    },
  })
  const { data: stationData, loading: stationsAreLoading, error: stationError } = useQuery<GetStationsResponse>(GET_STATIONS, {
    variables: {
      isArchived: false,
    },
  });
  const [getLaborCategories, { data: laborCategoryData, loading: laborCategoriesAreLoading, error: laborCategoryError }] = useLazyQuery<GetAllLaborCategoriesResponse>(GET_ALL_LABOR_CATEGORIES);
  const [createJob] = useMutation(CREATE_JOB, {
    refetchQueries: [GET_ALL_JOBS],
  });

  /* Create Job */
  const handleCreateJob = async (values: CreateJobInput) => {
    const { errors } = await createJob({
      variables: {
        input: {
          name: values.jobName,
          defaultRate: values.defaultRate,
          laborCategoryID: values.laborCategoryId,
        },
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating job' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Job created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createJobModal = (
    <FormModal<CreateJobInput> title='Create Job' onSubmit={handleCreateJob} submitLabel='Create' initialValues={{ jobName: '', defaultRate: '', laborCategoryId: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Job Name' name='jobName' required />
        <SingleSelect label='Labor Category' name='laborCategoryId' required>
          {laborCategoriesAreLoading ?
            <View>
              <CircularSpinner size='small' />
            </View>
            :
            <>
              {laborCategoryData?.laborCategories.map((laborCategory) => (
                <Choice label={laborCategory.name} value={laborCategory.id} key={laborCategory.id} />
              ))}
            </>
          }
        </SingleSelect>
        <TextField label='Default Hourly Rate' name='defaultRate' type='currency' required />
      </View>
    </FormModal>
  );

  if (state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin && state.user?.roles[Products.Scheduling] !== SchedulingRoles.Manager) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (jobsError || laborCategoryError || stationError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/settings' />
          <Breadcrumb label='Jobs' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', maxWidth: '800px', marginBottom: '16px', '@media (max-width: 470px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
          <StyledHeading tag='h3'>Jobs</StyledHeading>

          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
            <OptionBar selectedValue={showArchived} onChange={setShowArchived}>
              <OptionItem label='Active' value='FALSE' />
              <OptionItem label='Archived' value='TRUE' />
            </OptionBar>

            <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
              <ModalLauncher modal={createJobModal}>
                {({ openModal }) => (
                  <Button label='Create Job' role='button' variant='primary' action={() => { getLaborCategories(); openModal(); }} />
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(jobsAreLoading || stationsAreLoading) ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <View style={{ boxSizing: 'border-box', maxWidth: '800px', gap: '16px' }}>
            {(jobData?.scheduleJobs || []).length === 0 ?
              <Card style={{ boxSizing: 'border-box', width: '100%' }}>
                <StyledParagraph>No jobs found.</StyledParagraph>
              </Card>
              :
              <>
                {jobData?.scheduleJobs?.map((job) => (
                  <JobCard key={job.id} job={job} allStations={stationData?.scheduleJobStations || []} />
                ))}
              </>
            }
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}

type JobCardProps = {
  job: ScheduleJob;
  allStations: Station[];
}

function JobCard(props: JobCardProps) {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAddingStation, setIsAddingStation] = useState<boolean>(false);
  const [stationDefaultBehavior, setStationDefaultBehavior] = useState<{ [stationId: string]: boolean }>({});
  const [getLaborCategories, { data: laborCategoryData, loading: laborCategoriesAreLoading }] = useLazyQuery<GetAllLaborCategoriesResponse>(GET_ALL_LABOR_CATEGORIES);
  const [editJob] = useMutation(EDIT_JOB);
  const [archiveJob] = useMutation(ARCHIVE_JOB, {
    refetchQueries: [{ query: GET_ALL_JOBS, variables: { isArchived: false } }, { query: GET_ALL_JOBS, variables: { isArchived: true } }],
  });
  const [unarchiveJob] = useMutation(UNARCHIVE_JOB, {
    refetchQueries: [{ query: GET_ALL_JOBS, variables: { isArchived: false } }, { query: GET_ALL_JOBS, variables: { isArchived: true } }],
  });
  const [deleteJob] = useMutation<DeleteJobResponse>(DELETE_JOB, {
    update(cache, { data }) {
      if (!data?.success) {
        return;
      }

      cache.evict({
        id: cache.identify(props.job)
      });
    },
  });
  const [assignStationToJob, { loading: assignStationIsLoading }] = useMutation<AssignStationToJobResponse>(ASSIGN_STATION_TO_JOB, {
    update(cache, { data }) {
      if (!data?.assignStationToScheduleJob) {
        return;
      }

      cache.modify({
        id: cache.identify(props.job),
        fields: {
          stations(existingStations = [], { readField }) {
            return [data.assignStationToScheduleJob, ...existingStations].sort((a, b) => {
              const aStation = readField('station', a);
              const aName = readField('name', aStation as Reference | StoreObject | undefined)?.toString().toLocaleLowerCase() || '';
              const bStation = readField('station', b);
              const bName = readField('name', bStation as Reference | StoreObject | undefined)?.toString().toLocaleLowerCase() || '';

              return aName.localeCompare(bName);
            })
          }
        }
      });
    },
  });
  const [editStationAssignment, { loading: editStationAssignmentIsLoading }] = useMutation<EditStationAssignmentResponse>(EDIT_STATION_ASSIGNMENT, {
    update(cache, { data }, { variables }) {
      if (!data?.editStationScheduleJobAssignment || !variables) {
        return;
      }

      cache.modify({
        id: cache.identify(props.job),
        fields: {
          stations(existingStations = [], { readField }) {
            return existingStations.map((station: Reference) => {
              const s = readField('station', station) as Reference | StoreObject | undefined;
              if (readField('id', s) === variables.stationId) {
                return data.editStationScheduleJobAssignment;
              }

              return station;
            });
          }
        },
      });
    },
  });
  const [deleteStationAssignment] = useMutation<DeleteStationAssignmentResponse>(DELETE_STATION_ASSIGNMENT, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables) {
        return;
      }

      cache.modify({
        id: cache.identify(props.job),
        fields: {
          stations(existingStations = [], { readField }) {
            return existingStations.filter((station: Reference) => {
              const s = readField('station', station) as Reference | StoreObject | undefined;
              return readField('id', s) !== variables.stationId;
            });
          }
        },
      });
    },
  });

  const filteredStations = props.allStations.filter((station) => {
    if (props.job.stations.some((s) => s.station.id === station.id)) {
      return false;
    }

    return true;
  });

  useEffect(() => {
    for (const station of props.job.stations) {
      setStationDefaultBehavior((prev) => ({ ...prev, [station.station.id]: station.automaticallyAddToEmployees }));
    }
  }, [props.job.stations]);

  /* Edit Job */
  const handleEditJob = async (values: EditJobInput) => {
    const { errors } = await editJob({
      variables: {
        id: values.jobId,
        input: {
          name: values.jobName,
          defaultRate: values.defaultRate,
          laborCategoryID: values.laborCategoryId,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update job' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editJobModal = (
    <FormModal<EditJobInput> title='Edit Job' onSubmit={handleEditJob} initialValues={{ jobId: '', jobName: '', defaultRate: '', laborCategoryId: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Job Name' name='jobName' required />
        <SingleSelect label='Labor Category' name='laborCategoryId' required>
          {laborCategoriesAreLoading ?
            <View>
              <CircularSpinner size='small' />
            </View>
            :
            <>
              {laborCategoryData?.laborCategories.map((laborCategory) => (
                <Choice label={laborCategory.name} value={laborCategory.id} key={laborCategory.id} />
              ))}
            </>
          }
        </SingleSelect>
        <TextField label='Default Hourly Rate' name='defaultRate' type='currency' required />
      </View>
    </FormModal>
  );

  /* Archive Job */
  const handleArchiveJob = async () => {
    const { errors } = await archiveJob({
      variables: {
        id: props.job.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to archive job' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Job archived' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const archiveJobModal = (
    <ConfirmModal title='Archive Job?' onConfirm={handleArchiveJob} confirmLabel='Archive'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This job will be archived. You can unarchive it later.</StyledParagraph>
        <StyledParagraph>After archiving, this job will not appear in the scheduler or on employee profiles.</StyledParagraph>
        <StyledParagraph>Existing shifts assigned to this job will be unaffected.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Unarchive Job */
  const handleUnarchiveJob = async () => {
    const { errors } = await unarchiveJob({
      variables: {
        id: props.job.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to restore job' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Job restored' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const unarchiveJobModal = (
    <ConfirmModal title='Restore Job?' onConfirm={handleUnarchiveJob} confirmLabel='Restore'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>After restoring, this job will appear in the scheduler and can be assigned to new and existing shifts.</StyledParagraph>
        <StyledParagraph>Employees previously assigned to this job will be able to view the job and their assigned pay rate on their profile page.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Delete Job */
  const handleDeleteJob = async (values: DeleteJobInput) => {
    if (values.jobName !== props.job.name) {
      return;
    }

    const { errors } = await deleteJob({
      variables: {
        id: props.job.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to delete job' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Job deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteJobModal = (
    <FormModal<DeleteJobInput> title='Delete Job?' onSubmit={handleDeleteJob} submitLabel='Delete' destructive initialValues={{ jobName: '' }}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph bold style={{ color: Colors.error500 }}>This job and all shifts assigned to it will be permanently deleted.</StyledParagraph>
        <TextField label='Type the name of this job to proceed' description={`Enter "${props.job.name}" below`} name='jobName' required validate={(_, value: string) => {
          if (value !== props.job.name) {
            return 'Name does not match';
          }

          return null;
        }} />
      </View>
    </FormModal>
  );

  /* Assign Station */
  const assignStationForm = useForm<AssignStationToJobInput>({
    initialValues: {
      stationId: '',
      automaticallyAddToEmployees: false,
    },
    onSubmit: async (values: AssignStationToJobInput) => {
      const { errors } = await assignStationToJob({
        variables: {
          stationId: values.stationId,
          jobId: props.job.id,
          automaticallyAddToEmployees: values.automaticallyAddToEmployees,
        },
      });

      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Failed to assign station' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      } else {
        assignStationForm.resetValues();
        setIsAddingStation(false);
      }
    },
  });

  const stationsHelpModal = (
    <InfoModal title='Station Assignments'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>When creating or modifying a shift in the scheduler, you will be able to choose from these stations if the shift is assigned to this job.</StyledParagraph>
        <StyledParagraph>If the "Automatically add to employees" option is selected, any employee that is added to this job will also be automatically added to that station.</StyledParagraph>
      </View>
    </InfoModal>
  );

  /* Edit Station Assignment */
  const handleEditStationAssignment = async (stationId: string, automaticallyAddToEmployees: boolean) => {
    const { errors } = await editStationAssignment({
      variables: {
        stationId: stationId,
        jobId: props.job.id,
        automaticallyAddToEmployees: automaticallyAddToEmployees,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update station assignment' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      setStationDefaultBehavior(prev => ({ ...prev, [stationId]: automaticallyAddToEmployees }));
    }
  }

  /* Delete Station Assignment */
  const handleDeleteStationAssignment = async (stationId: string) => {
    const { errors } = await deleteStationAssignment({
      variables: {
        stationId: stationId,
        jobId: props.job.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to remove station assignment' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteStationAssignmentModal = (
    <ConfirmModal title='Remove Station?' onConfirm={handleDeleteStationAssignment} confirmLabel='Remove' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>You will no longer be able to select this station in the scheduler for shifts assigned to this job.</StyledParagraph>
        <StyledParagraph>Existing shifts will not be affected.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  return (
    <Card style={{ boxSizing: 'border-box', width: '100%' }}>
      <View style={{ gap: '16px' }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px', width: 'fit-content' }} onClick={() => { setIsOpen(!isOpen); setIsAddingStation(false); assignStationForm.resetValues(); }}>
            {isOpen ? <Icon icon={Icons.ChevronDown} size='small' key='icon-open' /> : <Icon icon={Icons.ChevronRight} size='small' key='icon-closed' />}
            <StyledHeading tag='h5' style={{ 'user-select': 'none' }}>{props.job.name}</StyledHeading>
          </View>

          <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
            <ModalLauncher modal={editJobModal}>
              {({ openModal: openEditModal }) => (
                <ModalLauncher modal={archiveJobModal}>
                  {({ openModal: openArchiveModal }) => (
                    <ModalLauncher modal={unarchiveJobModal}>
                      {({ openModal: openUnarchiveModal }) => (
                        <ModalLauncher modal={deleteJobModal}>
                          {({ openModal: openDeleteModal }) => (
                            <ActionMenu>
                              {!props.job.isArchived && <ActionItem label='Edit' onClick={() => { getLaborCategories(); openEditModal({ jobId: props.job.id, jobName: props.job.name, defaultRate: props.job.defaultRate, laborCategoryId: props.job.laborCategory.id }); }} />}
                              {!props.job.isArchived && <ActionItem label='Archive' onClick={openArchiveModal} />}
                              {props.job.isArchived && <ActionItem label='Restore' onClick={openUnarchiveModal} />}
                              {props.job.isArchived && <ActionItem label='Delete' onClick={openDeleteModal} />}
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      )}
                    </ModalLauncher>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>

        {isOpen &&
          <View style={{ marginLeft: '32px', gap: '16px' }}>
            <View>
              <StyledParagraph bold>Labor Category</StyledParagraph>
              <StyledParagraph>{props.job.laborCategory.name}</StyledParagraph>
            </View>
            <View>
              <StyledParagraph bold>Default Hourly Rate</StyledParagraph>
              <StyledParagraph>{props.job.defaultRate}</StyledParagraph>
            </View>

            <View style={{ marginTop: '24px' }}>
              <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                  <StyledHeading tag='h6'>Assigned Stations</StyledHeading>
                  <ModalLauncher modal={stationsHelpModal}>
                    {({ openModal }) => (
                      <Icon icon={Icons.Help} size='medium' style={{ color: Colors.neutral700, cursor: 'pointer' }} onClick={openModal} />
                    )}
                  </ModalLauncher>
                </View>

                {editStationAssignmentIsLoading &&
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                    <CircularSpinner size='xsmall' />
                    <StyledParagraph style={{ color: Colors.neutral700 }}>Saving changes...</StyledParagraph>
                  </View>
                }
              </View>

              {props.job.stations.length === 0 ?
                <StyledParagraph>There are no stations assigned to this job.</StyledParagraph>
                :
                <View style={{ maxWidth: '100%', overflow: 'auto' }}>
                  <Table>
                    <TableBody>
                      {props.job.stations.map((station) => {
                        return (
                          <TableRow key={station.station.id}>
                            <TableCell>{station.station.name}</TableCell>
                            <TableCell>
                              <Checkbox label='Automatically add to employees' name={station.station.id} checked={stationDefaultBehavior[station.station.id] || false} onChange={(name, value) => { handleEditStationAssignment(name, value); }} disabled={state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin} style={{ minWidth: '180px' }} />
                            </TableCell>
                            <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
                              <TableCell>
                                <ModalLauncher modal={deleteStationAssignmentModal}>
                                  {({ openModal }) => (
                                    <Button label='Remove' variant='tertiary' role='button' destructive action={() => { openModal(station.station.id); }} />
                                  )}
                                </ModalLauncher>
                              </TableCell>
                            </HasProductRole>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </View>
              }



              <View style={{ marginTop: '16px' }}>
                <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
                  {!isAddingStation && <Button label='Add Station' role='button' variant='tertiary' iconSize='small' leftIcon={Icons.Plus} action={() => { setIsAddingStation(true); }} disabled={filteredStations.length === 0} />}
                </HasProductRole>

                {isAddingStation &&
                  <Form handleSubmit={assignStationForm.handleSubmit}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
                      <SingleSelect name='stationId' placeholder='Choose a station' value={assignStationForm.values.stationId} error={assignStationForm.errors.stationId} onChange={assignStationForm.handleChange} onValidate={assignStationForm.handleValidate} required style={{ maxWidth: '300px', width: '300px' }}>
                        {filteredStations.map((station) => {
                          return (
                            <Choice key={station.id} label={station.name} value={station.id} />
                          );
                        })}
                      </SingleSelect>

                      <Checkbox name='automaticallyAddToEmployees' label='Automatically add to employees' checked={assignStationForm.values.automaticallyAddToEmployees} onChange={assignStationForm.handleChange} />

                      {<Button label='Add' variant='primary' role='button' action={assignStationForm.handleSubmit} disabled={assignStationForm.hasError} loading={assignStationIsLoading} />}
                    </View>
                  </Form>
                }
              </View>
            </View>
          </View>
        }
      </View>
    </Card>
  );
}