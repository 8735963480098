import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, Colors, ConfirmModal, ErrorPage, Form, FormModal, generateId, HasProductRole, Icon, Icons, InfoModal, ModalLauncher, NoPermission, OptionBar, OptionItem, Products, SchedulingRoles, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableRow, TextField, useAlertState, useAuthState, useForm, View } from "@barscience/global-components";
import { useState } from "react";

/* Get Schedules Query */
const GET_ALL_SCHEDULES = gql`
query getAllSchedules($isArchived: Boolean!) {
  schedules(isArchived: $isArchived) {
    id
    name
    isArchived
    jobs {
      id
      name
    }
  }
}
`;

type GetAllSchedulesResponse = {
  schedules: Schedule[];
}

type Schedule = {
  id: string;
  name: string;
  isArchived: boolean;
  jobs: Job[];
}

type Job = {
  id: string;
  name: string;
}

/* Create Schedule Mutation */
const CREATE_SCHEDULE = gql`
mutation createSchedule($name: String!) {
  createSchedule(name: $name) {
    id
    name
    isArchived
    jobs {
      id
      name
    }
  }
}
`;

type CreateScheduleInput = {
  scheduleName: string;
}

/* Edit Schedule Mutation */
const EDIT_SCHEDULE = gql`
mutation editSchedule($id: ID!, $name: String!) {
  editSchedule(id: $id, name: $name) {
    id
    name
    isArchived
  }
}
`;

type EditScheduleInput = {
  scheduleId: string;
  scheduleName: string;
}

/* Archive Schedule Mutation */
const ARCHIVE_SCHEDULE = gql`
mutation archiveSchedule($id: ID!) {
  archiveSchedule(id: $id) {
    id
    isArchived
  }
}
`;

/* Unarchive Schedule Mutation */
const UNARCHIVE_SCHEDULE = gql`
mutation unarchiveSchedule($id: ID!) {
  unarchiveSchedule(id: $id) {
    id
    isArchived
  }
}
`;

/* Delete Schedule Mutation */
const DELETE_SCHEDULE = gql`
mutation deleteSchedule($id: ID!) {
  success: deleteSchedule(id: $id)
}
`;

type DeleteSchedleResponse = {
  success: boolean;
}

type DeleteScheduleInput = {
  scheduleName: string;
}

/* Get Jobs Query */
const GET_JOBS = gql`
query getJobsToAssignToSchedule($isArchived: Boolean!) {
  scheduleJobs(isArchived: $isArchived) {
    id
    name
    laborCategory {
      id
      name
    }
  }
}
`;

type GetJobsResponse = {
  scheduleJobs?: AssignableJob[];
};

type AssignableJob = {
  id: string;
  name: string;
  laborCategory: {
    id: string;
    name: string;
  };
}

/* Assign Job Mutation */
const ASSIGN_JOB_TO_SCHEDULE = gql`
mutation assignJobToSchedule($jobId: ID!, $scheduleId: ID!) {
  assignJobToSchedule(jobId: $jobId, scheduleId: $scheduleId) {
    id
    name
    isArchived
    jobs {
      id
      name
    }
  }
}
`;

type AssignJobToScheduleInput = {
  jobId: string;
}

/* Unassign Job From Schedule Mutation */
const UNASSIGN_JOB_FROM_SCHEDULE = gql`
mutation unassignJobFromSchedule($jobId: ID!, $scheduleId: ID!) {
  unassignJobFromSchedule(jobId: $jobId, scheduleId: $scheduleId) {
    id
    name
    isArchived
    jobs {
      id
      name
    }
  }
}
`;

export default function Schedules() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [showArchived, setShowArchived] = useState('FALSE');
  const { data: scheduleData, loading: schedulesAreLoading, error: schedulesError } = useQuery<GetAllSchedulesResponse>(GET_ALL_SCHEDULES, {
    variables: {
      isArchived: showArchived === 'TRUE',
    },
  })
  const { data: jobData, loading: jobsAreLoading, error: jobsError } = useQuery<GetJobsResponse>(GET_JOBS, {
    variables: {
      isArchived: false,
    },
  });
  const [createSchedule] = useMutation(CREATE_SCHEDULE, {
    update(cache, { data }) {
      if (!data?.createSchedule) {
        return;
      }

      cache.updateQuery<GetAllSchedulesResponse, { isArchived: boolean }>({ query: GET_ALL_SCHEDULES, variables: { isArchived: false } }, (oldData) => {
        if (!oldData) {
          return;
        }

        return {
          schedules: [data.createSchedule, ...oldData.schedules].sort((a, b) => {
            const aName = a.name as string;
            const bName = b.name as string;

            return aName.localeCompare(bName);
          })
        };
      });
    },
  });

  /* Create Schedule */
  const handleCreateSchedule = async (values: CreateScheduleInput) => {
    const { errors } = await createSchedule({
      variables: {
        name: values.scheduleName,
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating schedule' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Schedule created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createScheduleModal = (
    <FormModal<CreateScheduleInput> title='Create Schedule' onSubmit={handleCreateSchedule} submitLabel='Create' initialValues={{ scheduleName: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Schedule Name' name='scheduleName' required />
      </View>
    </FormModal>
  );

  if (state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin && state.user?.roles[Products.Scheduling] !== SchedulingRoles.Manager) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (schedulesError || jobsError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/settings' />
          <Breadcrumb label='Schedules' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', maxWidth: '800px', marginBottom: '16px', '@media (max-width: 470px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
          <StyledHeading tag='h3'>Schedules</StyledHeading>

          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
            <OptionBar selectedValue={showArchived} onChange={setShowArchived}>
              <OptionItem label='Active' value='FALSE' />
              <OptionItem label='Archived' value='TRUE' />
            </OptionBar>

            <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
              <ModalLauncher modal={createScheduleModal}>
                {({ openModal }) => (
                  <Button label='Create Schedule' role='button' variant='primary' action={openModal} />
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(schedulesAreLoading || jobsAreLoading) ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <View style={{ boxSizing: 'border-box', maxWidth: '800px', gap: '16px' }}>
            {(scheduleData?.schedules || []).length === 0 ?
              <Card style={{ boxSizing: 'border-box', width: '100%' }}>
                <StyledParagraph>No schedules found.</StyledParagraph>
              </Card>
              :
              <>
                {scheduleData?.schedules?.map((schedule) => (
                  <ScheduleCard key={schedule.id} schedule={schedule} allJobs={jobData?.scheduleJobs || []} />
                ))}
              </>
            }
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}

type ScheduleCardProps = {
  schedule: Schedule;
  allJobs: AssignableJob[];
}

function ScheduleCard(props: ScheduleCardProps) {
  const { addAlert } = useAlertState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAddingJob, setIsAddingJob] = useState<boolean>(false);
  const [editSchedule] = useMutation(EDIT_SCHEDULE);
  const [archiveSchedule] = useMutation(ARCHIVE_SCHEDULE, {
    refetchQueries: [{ query: GET_ALL_SCHEDULES, variables: { isArchived: false } }, { query: GET_ALL_SCHEDULES, variables: { isArchived: true } }],
  });
  const [unarchiveSchedule] = useMutation(UNARCHIVE_SCHEDULE, {
    refetchQueries: [{ query: GET_ALL_SCHEDULES, variables: { isArchived: false } }, { query: GET_ALL_SCHEDULES, variables: { isArchived: true } }],
  });
  const [deleteSchedule] = useMutation<DeleteSchedleResponse>(DELETE_SCHEDULE, {
    update(cache, { data }) {
      if (!data?.success) {
        return;
      }

      cache.evict({
        id: cache.identify(props.schedule)
      });
    },
  });
  const [assignJobToSchedule, { loading: assignJobIsLoading }] = useMutation(ASSIGN_JOB_TO_SCHEDULE);
  const [unassignJobFromSchedule] = useMutation(UNASSIGN_JOB_FROM_SCHEDULE);

  const filteredJobs = props.allJobs.filter((job) => {
    if (props.schedule.jobs.some((j) => j.id === job.id)) {
      return false;
    }

    return true;
  });

  /* Edit Schedule */
  const handleEditSchedule = async (values: EditScheduleInput) => {
    const { errors } = await editSchedule({
      variables: {
        id: values.scheduleId,
        name: values.scheduleName,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update schedule' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editScheduleModal = (
    <FormModal<EditScheduleInput> title='Edit Schedule' onSubmit={handleEditSchedule} initialValues={{ scheduleId: '', scheduleName: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Schedule Name' name='scheduleName' required />
      </View>
    </FormModal>
  );

  /* Archive Schedule */
  const handleArchiveSchedule = async () => {
    const { errors } = await archiveSchedule({
      variables: {
        id: props.schedule.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to archive schedule' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Schedule archived' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const archiveScheduleModal = (
    <ConfirmModal title='Archive Schedule?' onConfirm={handleArchiveSchedule} confirmLabel='Archive'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This schedule will be archived. You can unarchive it later.</StyledParagraph>
        <StyledParagraph>After archiving, this schedule will not appear in the scheduler or on employee profiles.</StyledParagraph>
        <StyledParagraph>Managers and employees will not see previous shifts on this schedule.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Unarchive Schedule */
  const handleUnarchiveSchedule = async () => {
    const { errors } = await unarchiveSchedule({
      variables: {
        id: props.schedule.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to restore schedule' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Schedule restored' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const unarchiveScheduleModal = (
    <ConfirmModal title='Restore Job?' onConfirm={handleUnarchiveSchedule} confirmLabel='Restore'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>After restoring, this schedule will appear in the scheduler and new shifts can be assigned to it.</StyledParagraph>
        <StyledParagraph>Employees previously assigned to this schedule will be able to view the schedule and their previously assigned jobs and pay rates.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Delete Schedule */
  const handleDeleteSchedule = async (values: DeleteScheduleInput) => {
    if (values.scheduleName !== props.schedule.name) {
      return;
    }

    const { errors } = await deleteSchedule({
      variables: {
        id: props.schedule.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to delete schedule' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Schedule deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteScheduleModal = (
    <FormModal<DeleteScheduleInput> title='Delete Schedule?' onSubmit={handleDeleteSchedule} submitLabel='Delete' destructive initialValues={{ scheduleName: '' }}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph bold style={{ color: Colors.error500 }}>This schedule and all shifts assigned to it will be permanently deleted.</StyledParagraph>
        <TextField label='Type the name of this schedule to proceed' description={`Enter "${props.schedule.name}" below`} name='scheduleName' required validate={(_, value: string) => {
          if (value !== props.schedule.name) {
            return 'Name does not match';
          }

          return null;
        }} />
      </View>
    </FormModal>
  );

  /* Assign Job */
  const assignJobForm = useForm<AssignJobToScheduleInput>({
    initialValues: {
      jobId: '',
    },
    onSubmit: async (values: AssignJobToScheduleInput) => {
      const { errors } = await assignJobToSchedule({
        variables: {
          jobId: values.jobId,
          scheduleId: props.schedule.id,
        },
      });

      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Failed to assign job to schedule' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      } else {
        assignJobForm.resetValues();
        setIsAddingJob(false);
      }
    },
  });

  const jobsHelpModal = (
    <InfoModal title='Job Assignments'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>When creating or modifying a shift in the scheduler, you will be able to choose from these jobs if the shift is created on this schedule.</StyledParagraph>
        <StyledParagraph>Employees can be assigned jobs and pay rates separately for each schedule.</StyledParagraph>
      </View>
    </InfoModal>
  );

  /* Unassign Job */
  const handleUnassignJob = async (jobId: string) => {
    const { errors } = await unassignJobFromSchedule({
      variables: {
        jobId: jobId,
        scheduleId: props.schedule.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to remove job from schedule' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const unassignJobModal = (
    <ConfirmModal title='Remove Job?' onConfirm={handleUnassignJob} confirmLabel='Remove' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>You will no longer be able to select this job in the scheduler for shifts assigned to this schedule.</StyledParagraph>
        <StyledParagraph>Existing shifts will not be affected.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  return (
    <Card style={{ boxSizing: 'border-box', width: '100%' }}>
      <View style={{ gap: '16px' }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px', width: 'fit-content' }} onClick={() => { setIsOpen(!isOpen); setIsAddingJob(false); assignJobForm.resetValues(); }}>
            {isOpen ? <Icon icon={Icons.ChevronDown} size='small' key='icon-open' /> : <Icon icon={Icons.ChevronRight} size='small' key='icon-closed' />}
            <StyledHeading tag='h5' style={{ 'user-select': 'none' }}>{props.schedule.name}</StyledHeading>
          </View>

          <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
            <ModalLauncher modal={editScheduleModal}>
              {({ openModal: openEditModal }) => (
                <ModalLauncher modal={archiveScheduleModal}>
                  {({ openModal: openArchiveModal }) => (
                    <ModalLauncher modal={unarchiveScheduleModal}>
                      {({ openModal: openUnarchiveModal }) => (
                        <ModalLauncher modal={deleteScheduleModal}>
                          {({ openModal: openDeleteModal }) => (
                            <ActionMenu>
                              {!props.schedule.isArchived && <ActionItem label='Edit' onClick={() => { openEditModal({ scheduleId: props.schedule.id, scheduleName: props.schedule.name }); }} />}
                              {!props.schedule.isArchived && <ActionItem label='Archive' onClick={openArchiveModal} />}
                              {props.schedule.isArchived && <ActionItem label='Restore' onClick={openUnarchiveModal} />}
                              {props.schedule.isArchived && <ActionItem label='Delete' onClick={openDeleteModal} />}
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      )}
                    </ModalLauncher>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>

        {isOpen &&
          <View style={{ marginLeft: '32px', gap: '16px' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
              <StyledHeading tag='h6' style={{ fontSize: '18px' }}>Assigned Jobs</StyledHeading>
              <ModalLauncher modal={jobsHelpModal}>
                {({ openModal }) => (
                  <Icon icon={Icons.Help} size='medium' style={{ color: Colors.neutral700, cursor: 'pointer' }} onClick={openModal} />
                )}
              </ModalLauncher>
            </View>

            {props.schedule.jobs.length === 0 ?
              <StyledParagraph>There are no jobs assigned to this schedule.</StyledParagraph>
              :
              <View style={{ maxWidth: '100%', overflow: 'auto' }}>
                <Table>
                  <TableBody>
                    {props.schedule.jobs.map((job) => {
                      return (
                        <TableRow key={job.id}>
                          <TableCell>{job.name}</TableCell>
                          <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
                            <TableCell>
                              <ModalLauncher modal={unassignJobModal}>
                                {({ openModal }) => (
                                  <Button label='Remove' variant='tertiary' role='button' destructive action={() => { openModal(job.id); }} />
                                )}
                              </ModalLauncher>
                            </TableCell>
                          </HasProductRole>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </View>
            }



            <View style={{ marginTop: '16px' }}>
              <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
                {!isAddingJob && <Button label='Add Job' role='button' variant='tertiary' iconSize='small' leftIcon={Icons.Plus} action={() => { setIsAddingJob(true); }} disabled={filteredJobs.length === 0} />}
              </HasProductRole>

              {isAddingJob &&
                <Form handleSubmit={assignJobForm.handleSubmit}>
                  <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
                    <SingleSelect name='jobId' placeholder='Choose a job' value={assignJobForm.values.jobId} error={assignJobForm.errors.jobId} onChange={assignJobForm.handleChange} onValidate={assignJobForm.handleValidate} required style={{ maxWidth: '300px', width: '300px' }}>
                      {filteredJobs.map((job) => {
                        return (
                          <Choice key={job.id} label={job.name} value={job.id} />
                        );
                      })}
                    </SingleSelect>

                    {<Button label='Add' variant='primary' role='button' action={assignJobForm.handleSubmit} disabled={assignJobForm.hasError} loading={assignJobIsLoading} />}
                  </View>
                </Form>
              }
            </View>
          </View>
        }
      </View>
    </Card>
  );
}